<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Customer Service</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                        <div v-else class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <img :src="form.parsed_avatar" alt="" width="200px" class="rounded">
                            </center>
                            <br>
                            <form v-on:submit.prevent="editCS" enctype="multipart/form-data">
                                <label> Avatar (png, jpg) </label>
                                <input ref="file" type="file" @change="onFileChange" class="form-control">
                                <br>
                                <label for="">Nama</label>
                                <input type="text" class="form-control" v-model="form.name" required>
                                <br>
                                <label for="">Role</label>
                                <input type="text" class="form-control" v-model="form.role" required>
                                <br>
                                <label for="">Nomor Whatsapp</label>
                                <input type="number" class="form-control" v-model="form.phone" required>
                                <br>
                                <label for="">Email</label>
                                <input type="email" class="form-control" v-model="form.email" required>
                                <br>
                                <label for="">Password</label>
                                <input type="text" class="form-control" v-model="form.password" required>
                                <br>
                                <label for="">Device</label>
                                <multiselect v-model="form.device" tag-placeholder="Pilih device"
                                    placeholder="Pilih device" label="name" track-by="id" :options="device"
                                    :multiple="true"></multiselect>
                                <br>
                                <button class="btn btn-success btn-block">Simpan</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    //import moment from 'moment'
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    avatar: "",
                    name: "",
                    role: "",
                    phone: "",
                    email: "",
                    password: "",
                    device: [],
                    old_avatar: "",
                    parsed_avatar: "",
                },
                device: [],
                isLoading: true
            };
        },
        async mounted() {
            await this.getDevice({ status: "all" })
            await this.getCS({id: this.$route.query.id})
        },
        methods: {
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            async getCS(param) {
                let res = await this.$store.dispatch("customer_service_detail", param);
                res = res.data.data.customer_service
                this.form = {
                    avatar: "",
                    name: res.name,
                    role: res.role,
                    phone: res.phone,
                    email: res.email,
                    password: res.password,
                    device: [],
                    old_avatar: res.avatar ?? "",
                    parsed_avatar: "",
                }

                //DEVICE
                res.device = JSON.parse(res.device) ?? []
                for(let i = 0;i < res.device.length;i++){
                    for(let j = 0;j < this.device.length;j++){
                        if(res.device[i].id == this.device[j].id){
                            //PUSH
                            this.form.device.push(this.device[j])
                        }
                    }
                }
                this.form.parsed_avatar = res.avatar

                this.isLoading = false
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            async editCS(){
                try{
                    const formData = new FormData();

                    let guid = ""

                    if(this.form.avatar){
                        formData.append('file', this.form.avatar)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        guid = response.data.data.url
                    }else{
                        guid = this.form.old_avatar
                    }            

                    let data = {
                        id: this.$route.query.id,
                        avatar: guid,
                        name: this.form.name,
                        role: this.form.role,
                        phone: this.form.phone,
                        email: this.form.email,
                        password: this.form.password,
                        device: this.form.device,
                    }

                    let update_cs = await this.$store.dispatch('customer_service_update', data)
                    this.$toast.success(update_cs.data.message)
                    this.getCS({id: this.$route.query.id})
                    this.$refs.file.value=null;
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            isURL(value){
                var regexp = /^(ftp|http|https|chrome|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gum
                return regexp.test(value);
            }
        },
    };
</script>

<style scoped>
    
</style>
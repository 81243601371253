<template>
    <div>
        <center>
            <h4>Tambah Customer Service</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addCS" enctype="multipart/form-data">
            <label> Avatar (png, jpg) </label>
            <input ref="file" type="file" @change="onFileChange" class="form-control">
            <br>
            <label for="">Nama</label>
            <input type="text" class="form-control" v-model="form.name" required>
            <br>
            <label for="">Role</label>
            <input type="text" class="form-control" v-model="form.role" required>
            <br>
            <label for="">Nomor Whatsapp</label>
            <input type="number" class="form-control" v-model="form.phone" required>
            <br>
            <label for="">Email</label>
            <input type="email" class="form-control" v-model="form.email" required>
            <br>
            <label for="">Password</label>
            <input type="text" class="form-control" v-model="form.password" required>
            <br>
            <label for="">Device</label>
            <multiselect v-model="form.device" tag-placeholder="Pilih device"
                placeholder="Pilih device" label="name" track-by="id" :options="device"
                :multiple="true"></multiselect>
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>
    //import moment from 'moment'
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['device'],
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    avatar: "",
                    name: "",
                    role: "",
                    phone: "",
                    email: "",
                    password: "",
                    device: [],
                },
            };
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            async addCS(){
                try{
                    const formData = new FormData();

                    let url = ""

                    if(this.form.avatar){
                        formData.append('file', this.form.avatar)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        url = response.data.data.url
                    }                    

                    let data = {
                        avatar: url,
                        name: this.form.name,
                        role: this.form.role,
                        phone: this.form.phone,
                        email: this.form.email,
                        password: this.form.password,
                        device: this.form.device,
                    }

                    let create_cs = await this.$store.dispatch('customer_service_create', data)
                    this.$toast.success(create_cs.data.message)
                    this.$root.$emit("toggle_modal", "new_cs")
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    
</style>
<template>
    <div>
        <center>
            <h4>Tambah Kategori</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addAutoText" enctype="multipart/form-data">
            <label for="">Nama Kategori</label>
            <input type="text" class="form-control" v-model="form.name" required>
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                form: {
                    name: ""
                },
            };
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async addAutoText(){
                try{
                    let create_chat_widget = await this.$store.dispatch('category_create', this.form)
                    this.$toast.success(create_chat_widget.data.message)
                    this.$root.$emit("toggle_modal", "new_category")
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    hr{
        margin-top: 15px;
        margin-bottom: 15px;
    }
</style>
<template>
    <div class="contents">
        <div class="crm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <!-- <div class="col-md-12">
                            <div class="alert alert-warning alert-dismissible fade show" style="display: block !important" role="alert">
                                <b> INFORMASI : </b>
                                <br>
                                <div> Ada pembaharuan untuk ringkasan dan chart. sehingga angka di dashboard mungkin kurang akurat untuk hari ini (11 Agustus 2021)</div>
                                <button type="button" class="close float-right" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div> -->
                            <div class="col-md-12">
                                <div class="breadcrumb-main">
                                    <h4 class="text-capitalize breadcrumb-title">{{ $t("dashboard.dashboard") }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <!-- <div class="col-md-6">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4> Click Per Day </h4>
                                </div>
                                <div class="col-md-4">
                                <input type="text" class="form-control" name="tanggal" id="picker2" placeholder="dd/mm/yyyy" required>
                            </div>
                            </div>
                            <hr style="margin-top:5px;margin-bottom:5px">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered" id="datatable-click-per-day">
                                    <thead>
                                        <tr class="userDatatable-header">
                                            <th>
                                                <span class="userDatatable-title">Hari</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Tanggal</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Jumlah Klik</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4> Click Per Link </h4>
                                </div>
                            </div>
                            <hr style="margin-top:5px;margin-bottom:5px">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered" id="datatable-click-per-link">
                                    <thead>
                                        <tr class="userDatatable-header">
                                            <th>
                                                <span class="userDatatable-title">Link</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Jumlah Klik</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4> Click Per Rotation </h4>
                                </div>
                            </div>
                            <hr style="margin-top:5px;margin-bottom:5px">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered" id="datatable-click-per-rotation">
                                    <thead>
                                        <tr class="userDatatable-header">
                                            <th>
                                                <span class="userDatatable-title">Link</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">No Whatsapp</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Klik</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Jumlah Klik</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-xxl-4 col-sm-6 col-ssm-12 mb-30">
                        <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                            <!-- Card 3 -->
                            <div
                                class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                <div>
                                    <div class="overview-content">
                                        <h1 id="contact">{{ contact }}</h1>
                                        <p>Order</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xxl-4 col-sm-6 col-ssm-12 mb-30">
                        <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                            <div
                                class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                <div>
                                    <div class="overview-content">
                                        <h1 id="pesan">0</h1>
                                        <p>Pesan Keluar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

    export default {
        components: {
            
        },
        data() {
            return {
                myPackageLoading: true,
                myPackage: {
                    name: "",
                    pesan: "0",
                    device: "0",
                    customer_service: "0",
                    campaign: "0",
                    order_online: "0",
                    link_rotator: "0",
                    chat_widget: "0",
                    expired_at: ""
                },
                statistics: {
                    message_sent: 0,
                    message_pending: 0,
                    message_failed: 0,
                    incoming_message: 0,
                },
                contact: 0,
                filter: {
                    date: moment().format("YYYY-MM-DD"),
                },
                chartData: {
                    labels: ['Terkirim', 'Pending', 'Gagal'],
                    datasets: [{
                        backgroundColor: ['#20c997', '#fa8b0c', '#e24c4b'],
                        data: [0, 0, 0]
                    }]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        watch: {
            filter: {
                handler(val) {
                    this.fetchStatistics(moment.utc(val.date).local().format("YYYY-MM-DD"))
                },
                deep: true
            },
        },
        mounted() {
            this.fetchPacakge()
            this.fetchStatistics()
        },
        methods: {
            async fetchStatistics(date = null) {
                try {
                    let p = await this.$store.dispatch('user_statistics', { date: date ?? this.filter.date })
                    this.statistics = p.data.data.statistics
                    this.contact = p.data.data.contact
                    this.chartData.datasets[0].data = [this.statistics.message_sent, this.statistics
                        .message_pending, this.statistics.message_failed
                    ]
                } catch (err) {
                    console.log(err)
                }
            },
            async fetchPacakge() {
                this.myPackageLoading = true
                try {
                    let p = await this.$store.dispatch('user_myPackage')
                    p = p.data.data.user_package
                    this.myPackage.name = p.package_data.name
                    this.myPackage.pesan = p.message_quota == "-1" ? "Unlimited" : p.message_quota
                    this.myPackage.device = p.max_device == "-1" ? "Unlimited" : p.max_device
                    this.myPackage.customer_service = p.max_cs == "-1" ? "Unlimited" : p.max_cs
                    this.myPackage.campaign = p.max_campaign == "-1" ? "Unlimited" : p.max_campaign
                    this.myPackage.order_online = p.max_order_online == "-1" ? "Unlimited" : p.max_order_online
                    this.myPackage.link_rotator = p.max_link_rotator == "-1" ? "Unlimited" : p.max_link_rotator
                    this.myPackage.chat_widget = p.max_chat_widget == "-1" ? "Unlimited" : p.max_chat_widget
                    this.myPackage.expired_at = moment.unix(p.expired_at / 1000).format('D MMMM YYYY')
                } catch (err) {
                    console.log(err)
                }
                this.myPackageLoading = false
            }
        }
    }
</script>
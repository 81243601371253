<template>
    <div class="contents">

        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">

                    <div class="breadcrumb-main">
                        <h4 class="text-capitalize breadcrumb-title">Chat</h4>
                    </div>

                </div>
            </div>
        </div>
        <div class="chat-area d-flex mb-40" style="justify-content:normal !important">
            <div class="mb-lg-0 mb-40 chat-sidebar">
                <div class="sidebar-group left-sidebar chat_sidebar">
                    <div id="chat" class="left-sidebar-wrap bg-white radius-xl active">
                        <div class="chat-wrapper">
                            <!-- <div class="search-header">
                                <form action="/" class="d-flex align-items-center">
                                    <span data-feather="search"></span>
                                    <input class="form-control mr-sm-2 border-0 box-shadow-none" type="search"
                                        placeholder="Search" aria-label="Search">
                                </form>
                            </div> -->
                            <!-- <div class="search-tab">
                                <ul class="nav ap-tab-main border-bottom text-capitalize" id="ueberTab" role="tablist">
                                    <li class="nav-item mr-0">
                                        <a class="nav-link active" id="first-tab" data-target="#panel_b_first"
                                            data-secondary="#panel_a_first" data-toggle="tab" href="#first" role="tab"
                                            aria-controls="first-tab" aria-selected="true">private
                                            chat</a>
                                    </li>
                                </ul>
                            </div> -->
                            <div class="search-body">
                                <div class="tab-content" id="ueberTabA">
                                    <div class="tab-pane fade show active" id="panel_a_first" role="tabpanel"
                                        aria-labelledby="first-tab">
                                        <ul class="user-list" v-if="!isChatListLoading">
                                            <div v-for="(chatList, idx) in $store.state.history.chat_lists" v-bind:key="idx" style="cursor:pointer">
                                                <li v-on:click="openChat(chatList.jid)" class="user-list-item" v-bind:id="'cl-' + chatList.jid">
                                                    <div class="user-list-item__wrapper">
                                                        <div class="avatar avatar-circle ml-0">
                                                            <img src="@/assets/img/ellipse1.png"
                                                                class="rounded-circle wh-46 d-flex bg-opacity-primary"
                                                                alt="image">
                                                            <div class="badge-direction-bottom">
                                                                <span class="chat-badge-dot avatar-online"></span>
                                                            </div>
                                                        </div>
                                                        <div class="users-list-body">
                                                            <div class="users-list-body-title">
                                                                <h6>{{ getName(chatList.jid, chatList.number) }}</h6>
                                                                <div class="text-limit" data-maxlength="10">
                                                                    <p class="mb-0">
                                                                        <span>{{ trimString(chatList.last_chat.text, 25) }}</span></p>
                                                                </div>
                                                            </div>
                                                            <div class="last-chat-time unread">
                                                                <small>{{ formatDateRelative(chatList.last_update) }}</small>

                                                                <div v-if="chatList.notification" class="total-message mt-1 d-flex justify-content-end">
                                                                    <span class="badge badge-success">{{ chatList.notification }}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                            
                                        </ul>
                                        <ul v-else>
                                            <br>
                                            <center>
                                                <div class="spinner-border spinner" role="status"></div>
                                            </center>
                                        </ul>
                                    </div>
                                </div>
                            </div><!-- Ends: .search-body -->
                        </div><!-- Ends: .chat-wrapper -->
                    </div>
                </div><!-- Ends: .sidebar group -->
            </div><!-- Ends: .mb-lg-0 -->
            <div class="tab-content" style="width: 100% !important" id="ueberTabB">
                <div class="tab-pane fade  show active" id="panel_b_first" role="tabpanel" aria-labelledby="first-tab">
                    <div v-if="isChatLoading">
                        <div class="chat-body bg-white radius-xl">
                            <div class="chat-header">
                                <div class="media chat-name align-items-center">
                                    <div class="media-body align-self-center ">
                                        <h5 class=" mb-0 fw-500 mb-2">Starsender Web Whatsapp</h5>
                                    </div>
                                </div>
                                <!-- Chat Options -->
                                <ul class="nav flex-nowrap">
                                    <li class="nav-item list-inline-item mr-0">
                                        <div class="dropdown">
                                            <a href="#" role="button" title="Details" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span data-feather="more-vertical"></span>
                                            </a>

                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item align-items-center d-flex" href="#"
                                                    data-chat-info-toggle="">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="users"></span>
                                                    <span>Create new group</span>
                                                </a>

                                                <a class="dropdown-item align-items-center d-flex" href="#">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="trash-2"></span>

                                                    <span>Delete conversation</span>
                                                </a>
                                                <a class="dropdown-item align-items-center d-flex" href="#">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="x-octagon"></span>

                                                    <span>Block & report</span>
                                                </a>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="chat-box p-xl-30 pl-lg-20 pr-lg-0">
                                <center>
                                    <div class="spinner-border spinner" role="status"></div>
                                </center>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentChat" class="chat">
                        <div class="chat-body bg-white radius-xl">
                            <div class="chat-header">
                                <div class="media chat-name align-items-center">
                                    <div class="media-body align-self-center ">
                                        <h5 class=" mb-0 fw-500 mb-2">{{ currentChat.name }}</h5>
                                        <div class="media-body__content d-flex align-items-center">
                                            <!-- <span class="badge-dot dot-success mr-1"></span> -->
                                            <small class="d-flex color-light fs-12 text-capitalize">
                                                {{ currentChat.number }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <!-- Chat Options -->
                                <ul class="nav flex-nowrap">
                                    <li class="nav-item list-inline-item mr-0">
                                        <div class="dropdown">
                                            <a href="#" role="button" title="Details" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span data-feather="more-vertical"></span>
                                            </a>

                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item align-items-center d-flex" href="#"
                                                    data-chat-info-toggle="">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="users"></span>
                                                    <span>Create new group</span>
                                                </a>

                                                <a class="dropdown-item align-items-center d-flex" href="#">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="trash-2"></span>

                                                    <span>Delete conversation</span>
                                                </a>
                                                <a class="dropdown-item align-items-center d-flex" href="#">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="x-octagon"></span>

                                                    <span>Block & report</span>
                                                </a>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="chat-box p-xl-30 pl-lg-20 pr-lg-0" ref="chatBox">
                                <!-- Start: Incomming -->
                                <div v-for="(chat, idx) in $store.state.history.chats" :key="idx">
                                    <div class="flex-1 incoming-chat" v-if="chat.from_me == 0">
                                        <div class="chat-text-box">
                                            <div class="media ">
                                                <div class="chat-text-box__photo ">
                                                    <img src="@/assets/img/author/1.jpg"
                                                        class="align-self-start mr-15 wh-46" alt="img">
                                                </div>
                                                <div class="media-body">
                                                    <div class="chat-text-box__content">
                                                        <div class="chat-text-box__title d-flex align-items-center">
                                                            <h6 class="fs-14">{{ currentChat.name }}</h6>
                                                            <span
                                                                class="chat-text-box__time fs-12 color-light fw-400 ml-15">{{ formatDateDetail(chat.created_at) }}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center mb-20 mt-10">
                                                            <div class="chat-text-box__subtitle p-20 bg-primary">
                                                                <p class="color-white">{{ chat.text }}</p>
                                                            </div>
                                                            <div class="chat-text-box__other d-flex">
                                                                <div class="chat-text-box__reaction px-sm-15 px-2">
    
                                                                    <div class="emotions">
                                                                        <div class="dropdown  dropdown-click ">
                                                                            <button
                                                                                class="btn-link border-0 bg-transparent p-0"
                                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                                aria-expanded="false">
                                                                                <span data-feather="smile"></span> </button>
                                                                            <div
                                                                                class="dropdown-default dropdown-bottomRight dropdown-menu-right dropdown-menu">
                                                                                <ul class="emotions__parent d-flex">
                                                                                    <li>
                                                                                        <a class="" href="#">
                                                                                            <img src="@/assets/img/svg/cool.png"
                                                                                                alt="emotions">
                                                                                        </a>
                                                                                    </li>
                                                                                    <li><a class="" href="#">
                                                                                            <img src="@/assets/img/svg/happy2.png"
                                                                                                alt="emotions">
                                                                                        </a></li>
                                                                                    <li><a class="" href="#">
                                                                                            <img src="@/assets/img/svg/happy.png"
                                                                                                alt="emotions">
                                                                                        </a></li>
                                                                                    <li><a class="" href="#">
                                                                                            <img src="@/assets/img/svg/shocked.png"
                                                                                                alt="emotions">
                                                                                        </a></li>
                                                                                    <li><a class="" href="#">
                                                                                            <img src="@/assets/img/svg/like.png"
                                                                                                alt="emotions">
                                                                                        </a></li>
                                                                                    <li><a class="" href="#">
                                                                                            <img src="@/assets/img/svg/heart.png"
                                                                                                alt="emotions">
                                                                                        </a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
    
                                                                </div>
    
                                                                <div class="dropdown dropdown-click">
                                                                    <button class="btn-link border-0 bg-transparent p-0"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">
                                                                        <span data-feather="more-horizontal"></span>
                                                                    </button>
                                                                    <div class="dropdown-default dropdown-bottomRight dropdown-menu-right dropdown-menu"
                                                                        style="">
                                                                        <a class="dropdown-item" href="#">Copy</a>
                                                                        <a class="dropdown-item" href="#">Quote</a>
                                                                        <a class="dropdown-item" href="#">Forward</a>
                                                                        <a class="dropdown-item" href="#">Report</a>
                                                                    </div>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-1 justify-content-end d-flex outgoing-chat mt-20" v-else>
                                        <div class="chat-text-box">
                                            <div class="media ">
                                                <div class="media-body">
                                                    <div class="chat-text-box__content">
                                                        <div class="chat-text-box__title d-flex align-items-center justify-content-end mb-2">
                                                            <span class="chat-text-box__time fs-12 color-light fw-400">{{ formatDateDetail(chat.created_at) }}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-end">
                                                            <div class="chat-text-box__other d-flex">
                                                                <div class="px-15">

                                                                    <div class="dropdown dropdown-click">
                                                                        <button class="btn-link border-0 bg-transparent p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <span data-feather="more-horizontal"></span>
                                                                        </button>
                                                                        <div class="dropdown-default dropdown-bottomRight dropdown-menu-right dropdown-menu" style="">
                                                                            <a class="dropdown-item" href="#">Copy</a>
                                                                            <a class="dropdown-item" href="#">Quote</a>
                                                                            <a class="dropdown-item" href="#">Forward</a>
                                                                            <a class="dropdown-item" href="#">Report</a>
                                                                            <a class="dropdown-item" href="#">remove</a>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="chat-text-box__subtitle p-20 bg-deep">
                                                                <p class="color-gray">{{ chat.text }}</p>
                                                               <p v-if="chat.file" class="color-gray"><a  class="color-black" :href="chat.file"><i class="fa-solid fa-file"></i> {{chat.file.substring(chat.file.lastIndexOf('/')+1)}}</a></p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-footer px-xl-30 px-lg-20 pb-lg-30 pt-1">
                                <div class="chat-type-text">
                                    <div class="pt-0 outline-0 pb-0 pr-0 pl-0 rounded-0 position-relative d-flex align-items-center"
                                        tabindex="-1">
                                        <div class="d-flex justify-content-between align-items-center w-100 flex-wrap">
                                        <CustomText v-model.lazy="message" @updateFile="updateFile"></CustomText>
                                        <!--    <div class=" flex-1 d-flex align-items-center chat-type-text__write ml-0">
                                                <a href="#">
                                                    <span data-feather="smile"></span></a>
                                                <input v-on:keyup.enter="sendMessage" v-model.lazy="message" class="form-control border-0 bg-transparent box-shadow-none"
                                                    placeholder="Type your message...">

                                            </div>-->
                                            <div class="chat-type-text__btn">
                                                <!-- <button type="button"
                                                    class="border-0 btn-deep color-light wh-50 p-10 rounded-circle">
                                                    <span data-feather="image"></span></button>
                                                <button type="button"
                                                    class="border-0 btn-deep color-light wh-50 p-10 rounded-circle">
                                                    <span data-feather="paperclip"></span></button> 
                                                <button v-on:click="sendMessage" type="button"
                                                    class="border-0 btn-primary wh-50 p-10 rounded-circle">
                                                    <span class="fa fa-paper-plane"></span></button>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="chat">
                        <div class="chat-body bg-white radius-xl">
                            <div class="chat-header">
                                <div class="media chat-name align-items-center">
                                    <div class="media-body align-self-center ">
                                        <h5 class=" mb-0 fw-500 mb-2">Starsender Web Whatsapp</h5>
                                    </div>
                                </div>
                                <!-- Chat Options -->
                                <ul class="nav flex-nowrap">
                                    <li class="nav-item list-inline-item mr-0">
                                        <div class="dropdown">
                                            <a href="#" role="button" title="Details" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span data-feather="more-vertical"></span>
                                            </a>

                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item align-items-center d-flex" href="#"
                                                    data-chat-info-toggle="">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="users"></span>
                                                    <span>Create new group</span>
                                                </a>

                                                <a class="dropdown-item align-items-center d-flex" href="#">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="trash-2"></span>

                                                    <span>Delete conversation</span>
                                                </a>
                                                <a class="dropdown-item align-items-center d-flex" href="#">
                                                    <!-- Default :: Inline SVG -->
                                                    <span data-feather="x-octagon"></span>

                                                    <span>Block & report</span>
                                                </a>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="chat-box p-xl-30 pl-lg-20 pr-lg-0">
                                <div class="flex-1 incoming-chat">
                                    <div class="chat-text-box">
                                        <div class="media ">
                                            <div class="chat-text-box__photo ">
                                                <img src="@/assets/img/author/1.jpg"
                                                    class="align-self-start mr-15 wh-46" alt="img">
                                            </div>
                                            <div class="media-body">
                                                <div class="chat-text-box__content">
                                                    <div class="chat-text-box__title d-flex align-items-center">
                                                        <h6 class="fs-14">Admin</h6>
                                                        <span
                                                            class="chat-text-box__time fs-12 color-light fw-400 ml-15">8:30
                                                            PM</span>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-20 mt-10">
                                                        <div class="chat-text-box__subtitle p-20 bg-primary">
                                                            <p class="color-white">Sekarang, Anda dapat mengirim dan menerima pesan tanpa perlu menghubungkan telepon Anda ke Internet.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- Ends: .tab-content -->
        </div><!-- Ends: .chat-area -->
    </div>
</template>

<script>
    import moment from 'moment'
    import CustomText from "@/components/module/CustomText.vue";


    export default {
        components: {
            CustomText,
        },
        data() {
            return {
                deviceID: 0,
                chatLists: [],
                contacts: [],
                isSelected: [],
                currentChat: null,
                chats: [],
                fn: {},
                message: "",
                isChatListLoading: true,
                isChatLoading: false,
                lastJID: null,
                file:null
            };
        },
        beforeDestroy() {
            this.$root.$off("ws", this.fn);
        },
        watch: {
            '$store.state.history.chats': function () {
            // DOM not updated yet

            this.$nextTick(function () {
                this.scrollToElement()
            });
            }
        },
        async mounted() {
            this.deviceID = this.$route.params.id
           
            this.fn = (param) => {
               
                if(param == "pong") return
                param = JSON.parse(param)
                console.log(param)
                if (param.event == "incoming") {
                    if (param.data.message.device_id == this.deviceID) {
                        let newChat = {
                            jid: param.data.message.jid,
                            last_chat: {
                                text: param.data.message.text,
                                file: param.data.message.file,
                                from_me: param.data.message.from_me
                            },
                            last_update: param.data.message.created_at,
                            number: param.data.message.number,
                            notification: 1
                        }

                        let chatLists = this.$store.state.history.chat_lists

                        for (let i = 0; i < chatLists.length; i++) {
                            if (chatLists[i].jid == param.data.message.jid) {
                                newChat.notification = chatLists[i].notification + 1
                                chatLists.splice(i, 1)
                            }
                        }

                        if(param.data.message.jid == this.currentChat?.jid){
                            let chats = this.$store.state.history.chats
                            chats.push(param.data.message)
                            this.$store.commit("PUSH_HISTORY_CHATS", chats)

                            newChat.notification = 0
                        }

                        chatLists = [newChat, ...chatLists]
                        this.$store.commit("PUSH_CHAT_LIST", chatLists)

                    }
                }
            }
            this.$root.$on("ws", this.fn);

            await this.getContact()
            await this.getChatLists()
        },
        methods: {
            formatDateRelative(unix) {
                return moment(unix).fromNow()
            },
            formatDateDetail(unix) {
                return moment(unix).format("LLL")
            },
            async getContact(){
                try{
                    let res = await this.$store.dispatch("contact_load_from_device", {
                        id: parseInt(this.deviceID)
                    });

                    let contacts = res.data.data.contacts
                    for (let i = 0; i < contacts.length; i++) {
                        contacts[i].number = this.formatNumber(contacts[i].number, contacts[i].is_group)
                    }

                    this.$store.commit("PUSH_HISTORY_CONTACTS", contacts)
                }catch(err){
                    console.log(err)
                }

            },
            getName(jid, number){
                let contact = this.$store.state.history.contacts.find(el => el.number == jid)
                if(contact?.name){
                    return contact?.name
                }
                // for (let i = 0; i < contacts.length; i++) {
                //     let formattedNumber = this.formatNumber(contacts[i].number, contacts[i].is_group)
                //     if(formattedNumber == jid){
                //         if(contacts[i].name != ""){
                //             return contacts[i].name
                //         }else{
                //             return contacts[i].number
                //         }
                //     }
                // }

                return number
            },
            async getChatLists() {
                let res = await this.$store.dispatch("history_chat_lists", {
                    id: parseInt(this.deviceID)
                });
                this.$store.commit("PUSH_CHAT_LIST", res.data.data.chat_lists)
                this.isChatListLoading = false
            },
            async getChats(jid) {
                let res = await this.$store.dispatch("history_chats", {
                    id: parseInt(this.deviceID),
                    jid: jid
                });

                return res.data.data.chats
            },
			updateFile(e) {
				this.file=e;
			},
            async sendMessage(){
                if(this.message == "" && this.file== null ){
                    
                    return
                }
                
                let text = this.message 
                let number = this.currentChat.number
                this.message = ""

                let url = ""
				if(this.file){
					let formData = new FormData();
					formData.append('file', this.file)
					let response = await this.$store.dispatch('cdn_upload', formData)
					url = response.data.data.url
				}
                //console.log("url file :"+url)
                let finalMessage = {
                    device_id: parseInt(this.deviceID),
                    schedule: moment().utc().valueOf(),
                    broadcast: [{
                        message_type: "text",
                        to: number,
                        text: text,
                        file: url,
                        delay: 0,
                    }]
                }

                try{
                    await this.$store.dispatch('outgoing_send', finalMessage)

                    let chats = this.$store.state.history.chats
                   
                    chats.push({
                        device_id: parseInt(this.deviceID),
                        from_me: 1,
                        jid: this.currentChat.jid,
                        number: number,
                        text: text,
                        file: url,
                        created_at: finalMessage.schedule,
                        updated_at: finalMessage.schedule
                    })
                    this.$store.commit("PUSH_HISTORY_CHATS", chats)

                    let chatLists = this.$store.state.history.chat_lists
                    let lastChatIndex = chatLists.findIndex(a => a.jid == this.currentChat.jid)
                    chatLists[lastChatIndex].last_chat.text = text
                    chatLists[lastChatIndex].last_update = finalMessage.schedule

                    this.$store.commit("PUSH_CHAT_LIST", chatLists)
                }catch(err){
                    this.$toast.error("Error to sending message")
                }
                
            },
            formatNumber(number, is_group){
                if(number.charAt(0) == "0"){
                    number = "62" + number.substring(1)
                }

                if(is_group){
                    number = number + "@g.us"
                }else{
                    number = number + "@s.whatsapp.net"
                }

                return number
            },
            async openChat(jid){
                if(this.currentChat?.jid == jid) return
                this.select(jid)
                this.openChatProcess(jid)
            },
            async openChatProcess(jid){
                this.isChatLoading = true

                this.currentChat = {}
                this.currentChat = this.$store.state.history.chat_lists.find(a => a.jid == jid)

                this.currentChat.name = this.getName(this.currentChat.jid, this.currentChat.number)
                this.$store.commit("PUSH_HISTORY_CHATS", await this.getChats(jid))
                //this.chats = chats

                let modifiedChat = this.currentChat
                modifiedChat.notification = 0
                let chatLists = this.$store.state.history.chat_lists.map(u => u.jid !== jid ? u : modifiedChat)
                this.$store.commit("PUSH_CHAT_LIST", chatLists)
                this.isChatLoading = false
            },
            select(id) {
                // this.isSelected = []
                // if(this.isSelected.includes(id)) {
                //     this.isSelected = this.isSelected.filter(s => s !== id )
                // } else {
                //     this.$set(this.isSelected, 0, id)
                // }

                if(this.lastJID){
                    var elm = document.getElementById("cl-" + this.lastJID);
                    elm.classList.remove("chatactive");
                }

                var element = document.getElementById("cl-" + id);
                element.classList.add("chatactive");
                this.lastJID = id
            },
            trimString (string, length) {
                if(string == null || string == ""){
                    return ""
                }
                return string.length > length ? string.substring(0, length) + '...' : string;            
            },
            scrollToElement() {
                var container = this.$el.querySelector(".chat-box");
                container.scrollTop = container.scrollHeight;
            }
        },
    };
</script>

<style scoped>
    .chatactive{
        background-color: #e4e6ea;
    }
</style>

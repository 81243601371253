<template>
    <div>
        <center>
            <h4>Tambah AutoText</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addAutoText" enctype="multipart/form-data">
            <label for="">AutoText</label>
            <CustomTextArea v-model="form.autotext" pos="bottom"></CustomTextArea>
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>
    //import moment from 'moment'
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            CustomTextArea
        },
        data() {
            return {
                form: {
                    autotext: ""
                },
            };
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async addAutoText(){
                try{
                    let create_chat_widget = await this.$store.dispatch('autotext_create', this.form)
                    this.$toast.success(create_chat_widget.data.message)
                    this.$root.$emit("toggle_modal", "new_autotext")
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    hr{
        margin-top: 15px;
        margin-bottom: 15px;
    }
</style>
<template>
    <div>
        <center>
            <h4>Tambah Alamat Toko</h4>
        </center>
        <br>
        <form v-on:submit.prevent="create" enctype="multipart/form-data">
            <label for="">Label Toko</label>
            <input type="text" class="form-control" v-model="form.name" required>
            <br>
            <label for="">Provinsi</label>
            <div v-if="isProvinceLoading">
                <div class="spinner-border spinner" role="status"></div>
                Loading
            </div>
            <multiselect v-else v-model="province" tag-placeholder="Pilih Provinsi" placeholder="Cari Provinsi"
                label="province" track-by="province_id" :options="provinces" :multiple="false">
            </multiselect>
            <br>
            <label for="">Kota</label>
            <div v-if="isCityLoading">
                <div class="spinner-border spinner" role="status"></div>
                Loading
            </div>
            <multiselect v-else v-model="city" tag-placeholder="Pilih Kota" placeholder="Cari Kota" label="label"
                track-by="city_id" :options="cities" :multiple="false">
            </multiselect>
            <br>
            <label for="">Kecamatan</label>
            <div v-if="isSubdistrictLoading">
                <div class="spinner-border spinner" role="status"></div>
                Loading
            </div>
            <multiselect v-else v-model="form.origin" tag-placeholder="Pilih Kecamatan" placeholder="Cari Kecamatan"
                label="subdistrict_name" track-by="subdistrict_id" :options="subdistricts" :multiple="false">
            </multiselect>
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    name: "",
                    origin: "",
                },
                province: "",
                city: "",
                provinces: [],
                cities: [],
                subdistricts: [],
                isProvinceLoading: true,
                isCityLoading: false,
                isSubdistrictLoading: false,
                isLoading: false
            };
        },
        watch: {
            province: {
                handler(val, old) {
                    if (val?.province_id && val?.province_id != old) {
                        this.loadCityData(val?.province_id)
                        this.city = null
                        this.form.origin = null
                        this.cities = []
                        this.subdistricts = []
                    }
                },
                deep: true
            },
            city: {
                handler(val, old) {
                    if (val?.city_id && val?.city_id != old) {
                        this.loadSubdistrictData(val?.city_id)
                        this.form.origin = null
                        this.subdistricts = []
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });

            this.loadProvinceData()
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async loadProvinceData() {
                this.isProvinceLoading = true
                try {
                    let detail = await this.$store.dispatch("rajaongkir_province")
                    this.provinces = detail.data.data.provinces
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
                this.isProvinceLoading = false
            },
            async loadCityData(id) {
                this.isCityLoading = true
                try {
                    this.cities = []

                    let detail = await this.$store.dispatch("rajaongkir_city", {
                        id
                    })

                    for (let i = 0; i < detail.data.data.cities.length; i++) {
                        const element = detail.data.data.cities[i];
                        this.cities.push({
                            city_id: element.city_id,
                            label: element.type + " " + element.city_name
                        })
                    }
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
                this.isCityLoading = false
            },
            async loadSubdistrictData(id) {
                this.isSubdistrictLoading = true
                try {
                    this.subdistricts = []

                    let detail = await this.$store.dispatch("rajaongkir_subdistrict", {
                        id
                    })
                    this.subdistricts = detail.data.data.subdistricts
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
                this.isSubdistrictLoading = false
            },
            async create() {
                this.isLoading = true
                try {
                    let create = await this.$store.dispatch('toko_create', {
                        name: this.form.name,
                        origin: this.province.province_id + "-" + this.city.city_id + "-" + this.form.origin.subdistrict_id
                    })
                    this.$toast.success(create.data.message)
                    this.$root.$emit("toggle_modal", "new_toko")
                    this.$root.$emit("refresh_table", true)
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false
            }
        },
    };
</script>

<style scoped>
    hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
</style>
<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">
                                        <router-link to="/cs-page/toko">Alamat Toko</router-link> / Edit Alamat Toko
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="update">
                                <label> Label Toko </label>
                                <input type="text" class="form-control" v-model="form.name" required>
                                <br>
                                <label for="">Provinsi</label>
                                <div v-if="isProvinceLoading">
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </div>
                                <multiselect v-else v-model="province" tag-placeholder="Pilih Provinsi"
                                    placeholder="Cari Provinsi" label="province" track-by="province_id"
                                    :options="provinces" :multiple="false">
                                </multiselect>
                                <br>
                                <label for="">Kota</label>
                                <div v-if="isCityLoading">
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </div>
                                <multiselect v-else v-model="city" tag-placeholder="Pilih Kota" placeholder="Cari Kota"
                                    label="label" track-by="city_id" :options="cities" :multiple="false">
                                </multiselect>
                                <br>
                                <label for="">Kecamatan</label>
                                <div v-if="isSubdistrictLoading">
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </div>
                                <multiselect v-else v-model="form.origin" tag-placeholder="Pilih Kecamatan"
                                    placeholder="Cari Kecamatan" label="subdistrict_name" track-by="subdistrict_id"
                                    :options="subdistricts" :multiple="false">
                                </multiselect>
                                <br>
                                <button class="btn btn-success btn-block" id="simpan_edit" type="submit"> Simpan
                                </button>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'


    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                id: 0,
                form: {
                    name: "",
                    origin: ""
                },
                province: "",
                city: "",
                provinces: [],
                cities: [],
                subdistricts: [],
                isLoading: true,
                isProvinceLoading: true,
                isCityLoading: true,
                isSubdistrictLoading: true,
            };
        },
        watch: {
            province: {
                handler(val, old) {
                    if (val?.province_id && val?.province_id != old) {
                        this.loadCityData(val?.province_id)
                        this.city = null
                        this.form.origin = null
                        this.cities = []
                        this.subdistricts = []
                    }
                },
                deep: true
            },
            city: {
                handler(val, old) {
                    if (val?.city_id && val?.city_id != old) {
                        this.loadSubdistrictData(val?.city_id)
                        this.form.origin = null
                        this.subdistricts = []
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.id = this.$route.query.id
            this.loadData()

            this.loadProvinceData()
        },
        methods: {
            async loadData() {
                try {
                    let detail = await this.$store.dispatch("toko_detail", {
                        id: this.id
                    })
                    this.form = detail.data.data.origin

                    let origin = this.form.origin.split("-")
                    this.province = origin[0]
                    this.city = origin[1]
                    this.form.origin = origin[2]

                    this.loadCityData(origin[0])
                    this.loadSubdistrictData(origin[1])

                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/cs-page/toko")
                }
            },
            async loadProvinceData() {
                this.isProvinceLoading = true
                try {
                    let detail = await this.$store.dispatch("rajaongkir_province")
                    this.provinces = detail.data.data.provinces

                    if(this.province){
                        this.province = await this.provinces.find(option => option.province_id === this.province);
                    }
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
                this.isProvinceLoading = false
            },
            async loadCityData(id) {
                this.isCityLoading = true
                try {
                    this.cities = []

                    let detail = await this.$store.dispatch("rajaongkir_city", {
                        id
                    })

                    for (let i = 0; i < detail.data.data.cities.length; i++) {
                        const element = detail.data.data.cities[i];
                        this.cities.push({
                            city_id: element.city_id,
                            label: element.type + " " + element.city_name
                        })
                    }

                    if(this.city){
                        this.city = this.cities.find(option => option.city_id === this.city);
                    }
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
                this.isCityLoading = false
            },
            async loadSubdistrictData(id) {
                this.isSubdistrictLoading = true
                try {
                    this.subdistricts = []

                    let detail = await this.$store.dispatch("rajaongkir_subdistrict", {
                        id
                    })
                    this.subdistricts = detail.data.data.subdistricts

                    if(this.form.origin){
                        this.form.origin = this.subdistricts.find(option => option.subdistrict_id === this.form.origin);
                    }
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
                this.isSubdistrictLoading = false
            },
            async update() {
                try {
                    let response = await this.$store.dispatch('toko_update', {
                        id: this.form.id,
                        name: this.form.name,
                        origin: this.province.province_id + "-" + this.city.city_id + "-" + this.form.origin.subdistrict_id
                    })
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
        },
    };
</script>
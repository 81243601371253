<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">
                                        <router-link to="/cs-page/autotext">AutoText</router-link> / Edit AutoText
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="updateAutotext">
                                <label> AutoText </label>
                                <CustomTextArea v-model="form.autotext" pos="bottom"></CustomTextArea>
                                <br>
                                <button class="btn btn-success btn-block" id="simpan_edit" type="submit"> Simpan </button>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";


export default {
    components: {
        CustomTextArea
    },
      data() {
        return {
            id: 0,
            form: {
                autotext: "",
            },
            isLoading: true
        };
      },
      mounted() {
        this.id = this.$route.query.id
        this.loadData()
      },
      methods: {
        async loadData(){
            try{
                let detail = await this.$store.dispatch("autotext_detail", { id: this.id })
                this.form = detail.data.data.autotext
                this.isLoading = false
            }catch(err){
                this.$toast.error(err.response.data.message)
                this.$router.push("/cs-page/autotext")
            }
        },
        async updateAutotext(){
            try{
                let response = await this.$store.dispatch('autotext_update', this.form)
                this.$toast.success(response.data.message);
            }catch(err){
                this.$toast.error(err.response.data.message);
            }
        },
        toggleModal(param) {      
            this.$modal.toggle(param);
        },
      },
    };
</script>    